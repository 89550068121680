import { Column, Row, Workbook, Worksheet } from 'exceljs'
import { saveAs } from 'file-saver'
import {
  Product,
  ProductBarcode,
  ProductCatalogAttribute,
  ProductFileGeneratorField,
  ProductFileGeneratorOptions,
  ProductWrapper,
  productImportAttributeKeys,
  productImportBarcodeKeys,
  productImportSiteKeys,
  productImportTagKeys,
  productImportWrapperKeys,
} from '../product.model'
import { CATALOG_DEFAULT_CODE } from '../../catalogs/catalog.const'
import { LOCALE_DEFAULT_CODE } from '../../../consts/locale.const'
import { getCatalogValue } from '../../catalogs/catalog.lib'
import { Brand } from '../../brands/brand.model'
import { Replenishment, replenishmentsExcelKeys } from '../../replenishments'
import { Manufacturer } from '../../manufacturers'
import { Location } from '../../locations'
import {
  FileUpdateMethod,
  getCatalogPriceValue,
  getProductAttributeByField,
} from '@evologi/shared/data-access-api'

export function generateUpdateXlsFile(
  options: ProductFileGeneratorOptions,
  catalogCode = CATALOG_DEFAULT_CODE,
  locale = LOCALE_DEFAULT_CODE,
  products?: Product[],
  brands?: Brand[],
  manufacturers?: Manufacturer[],
  sites?: Location[],
): void {
  // Create workbook
  const workbook = new Workbook()
  if (options.tabs.includes('PRODUCTS')) {
    generateProductsSheet(
      workbook,
      options,
      catalogCode,
      locale,
      products,
      brands,
      manufacturers,
    )
  }

  if (options.tabs.includes('ATTRIBUTES')) {
    generateAttributesSheet(options, workbook, catalogCode, locale, products)
  }

  if (options.tabs.includes('BARCODES')) {
    generateBarcodesSheet(options, workbook, products)
  }

  if (options.tabs.includes('SITES')) {
    generateSitesSheet(options, workbook, products, sites)
  }

  if (options.tabs.includes('TAG')) {
    generateTagsSheet(options, workbook, products)
  }

  if (options.tabs.includes('WRAPPERS')) {
    generateWrappersSheet(options, workbook, products)
  }

  workbook.xlsx
    .writeBuffer()
    .then((buffer: BlobPart) =>
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `prodotti.xlsx`,
      ),
    )
}

// File generation

function generateColumns(keys: string[]): Partial<Column>[] {
  const columns: Partial<Column>[] = []
  keys.map((el) =>
    columns.push({
      header: String(el),
      key: `KEY-${el}`,
      width: columnWidth(el),
    }),
  )
  return columns
}

function setHeaderStyle(
  worksheet: Worksheet,
  setHeaderColor: (key?: string) => string,
) {
  worksheet.getRow(1).eachCell((c, i) => {
    c.font = {
      color: {
        argb: i === 1 ? 'BD0000' : setHeaderColor(c.value?.toString() ?? ''),
      },
      bold: true,
    }
  })
  worksheet.getRow(1).protection = {
    locked: true,
  }
  worksheet.getRow(1).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'BCBCBC' },
  }
}

function columnWidth(key?: string): number | undefined {
  switch (key) {
    case 'azione':
      return 26
    case 'sku':
      return 12
    default:
      return undefined
  }
}

function getFieldType(key: string): string {
  switch (key) {
    case 'qty':
      return '0'
    case 'moltiplicatore':
      return '0'
    case 'lunghezza':
      return '#,##0.00'
    case 'larghezza':
      return '#,##0.00'
    case 'altezza':
      return '#,##0.00'
    case 'volume':
      return '#,##0.00'
    case 'peso':
      return '#,##0.00'
    case 'price.purchase':
      return '[$€-x-euro2] #,##0.00'
    case 'price.listing':
      return '[$€-x-euro2] #,##0.00'
    case 'price.discount':
      return '[$€-x-euro2] #,##0.00'
    case 'onHandQty':
      return '0'
    case 'availableQty':
      return '0'
    case 'incomingQty':
      return '0'
    default:
      return '@'
  }

  /*
  '@' - Text
  '0' - Integer
  '#,##0.00' - Number with 2 decimals and thousand separator
  '0%' - Percentage
  'dd/mm/yyyy' - Date
  '[$€-x-euro2] #,##0.00' - Currency (Euro)
  */
}

function startingColumns(
  options: ProductFileGeneratorOptions,
): Partial<Column>[] {
  return [
    {
      header: String(options.key),
      key: `KEY-${options.key}`,
      width: columnWidth(options.key),
    },
    {
      header: String('azione'),
      key: 'KEY-azione',
      width: columnWidth('azione'),
    },
  ]
}

function rowKeys(
  options: ProductFileGeneratorOptions,
  keys: string[],
): string[] {
  return options.key ? [options.key, 'azione', ...keys] : ['azione', ...keys]
}

function firstKeyRow(
  options: ProductFileGeneratorOptions,
  product: Product,
): string {
  const barcode = product.barcodes ? product.barcodes[0].value : product.SKU
  const key =
    options.key === 'id'
      ? product._id
      : options.key === 'sku'
        ? product.SKU
        : barcode
  return key
}

// Replenishments

export function generateReplFile(replenishments?: Replenishment[]): void {
  // Create workbook
  const workbook = new Workbook()
  const worksheet = workbook.addWorksheet('replenishments')

  // Columns
  worksheet.columns = generateColumns(replenishmentsExcelKeys)

  worksheet.getRow(1).protection = {
    locked: true,
  }
  worksheet.getRow(1).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'BCBCBC' },
  }
  worksheet.getRow(1).font = {
    bold: true,
  }

  if (replenishments?.length) {
    const rows: Partial<Row>[] = []
    replenishments.map((r) =>
      rows.push(parseReplRow(replenishmentsExcelKeys, r)),
    )
    worksheet.addRows(rows)
  } else {
    const row: Partial<Row> = parseEmptyReplRow(replenishmentsExcelKeys)
    worksheet.addRows([row])
  }
  worksheet.columns.map((c) => {
    c.numFmt = getFieldType(`${c.header ?? ''}`)
  })
  workbook.xlsx
    .writeBuffer()
    .then((buffer: BlobPart) =>
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `replenishments.xlsx`,
      ),
    )
}

function parseReplRow(
  keys: string[],
  replenishment: Replenishment,
): Partial<Row> {
  let rows: Partial<Row> = {}
  keys.map(
    (el) =>
      (rows = {
        ...rows,
        [`KEY-${el}`]: parseReplField(el, replenishment),
      }),
  )
  return rows
}

function parseEmptyReplRow(keys: string[]): Partial<Row> {
  let rows: Partial<Row> = {}
  keys.map(
    (el) =>
      (rows = {
        ...rows,
        [`KEY-${el}`]: parseEmptyReplField(el),
      }),
  )
  return rows
}

function parseReplField(key: string, replenishment: Replenishment) {
  let value: any
  switch (key) {
    case 'sku':
      value = `${replenishment.product?.SKU ?? ''}`
      break
  }
  return value
}

function parseEmptyReplField(key: string) {
  let value = ''
  switch (key) {
    case 'action':
      value = '[CREATE, INSERT, UPDATE, UPSERT]'
      break
    case 'source':
      value = '[PATH]'
      break
    case 'target':
      value = '[PATH]'
      break
    case 'priority':
      value = '[LOW, MEDIUM, HIGH]'
      break
  }
  return value
}

// Products
function generateProductsSheet(
  workbook: Workbook,
  options: ProductFileGeneratorOptions,
  catalogCode = CATALOG_DEFAULT_CODE,
  locale = LOCALE_DEFAULT_CODE,
  products?: Product[],
  brands?: Brand[],
  manufacturers?: Manufacturer[],
) {
  const worksheet = workbook.addWorksheet('prodotti')

  // Columns
  worksheet.columns = [
    ...startingColumns(options),
    ...options.fields.map((field) =>
      parseProdsColumnField(
        field, //catalogCode, locale,
      ),
    ),
  ]
  setHeaderStyle(worksheet, productHeaderColor)
  const keys: string[] = options.fields.map((field) => field.field)
  keys.push('azione')
  // Rows
  worksheet.addRow(parseEmptyProdRow(keys))
  if (products) {
    const _brands: Record<string, string> =
      brands?.reduce((acc, b) => ({ ...acc, [b._id]: b.name }), {}) || {}
    const _manufacturers: Record<string, string> =
      manufacturers?.reduce((acc, b) => ({ ...acc, [b._id]: b.name }), {}) || {}

    const rows = products.map((p) =>
      parseProductRow(
        p,
        p.brandId ? _brands[p.brandId] : undefined,
        p.manufacturerId ? _manufacturers[p.manufacturerId] : undefined,
        options,
        catalogCode,
        locale,
      ),
    )
    worksheet.addRows(rows)
  }
  worksheet.columns.map((c) => {
    c.numFmt = getFieldType(`${c.header ?? ''}`)
  })
}

function productHeaderColor(key?: string) {
  switch (key) {
    case 'sku':
      return 'BD0000'
    default:
      return '000000'
  }
}

function parseProductRow(
  product: Product,
  brandName: string | undefined,
  manufacturerName: string | undefined,
  options: ProductFileGeneratorOptions,
  catalogCode = CATALOG_DEFAULT_CODE,
  locale = LOCALE_DEFAULT_CODE,
): Partial<Row> {
  return {
    [`KEY-${options.key}`]: firstKeyRow(options, product),
    ...options.fields.reduce(
      (acc, field) => ({
        ...acc,
        ...parseProductField(
          product,
          brandName,
          manufacturerName,
          field,
          catalogCode,
          locale,
        ),
      }),
      {},
    ),
  }
}

function parseProductField(
  product: Product,
  brandName: string | undefined,
  manufacturerName: string | undefined,
  field: ProductFileGeneratorField,
  catalogCode: string,
  locale: string,
) {
  const key = field.field.includes('attribute') ? 'attribute' : field.field
  let value = undefined
  switch (key) {
    case 'sku':
      value = product.SKU
      break
    case 'gestione_lotti_attiva':
      value = product.batchManagement?.enabled
      break
    case 'gestione_lotti_richiesto_lotto':
      value = product.batchManagement?.lotRequired
      break
    case 'gestione_lotti_richiesta_data_scadenza':
      value = product.batchManagement?.expirationDateRequired
      break
    case 'gestione_lotti_richiesto_seriale':
      value = product.batchManagement?.serialRequired
      break
    case 'nome_brand':
      value = brandName
      break
    case 'dogana_descrizione':
      value = product.customsInfo?.description
      break
    case 'dogana_hs_code':
      value = product.customsInfo?.hsCode
      break
    case 'dogana_nazione_origine':
      value = product.customsInfo?.originCountry
      break
    case 'descrizione':
      value = getCatalogValue(product.description, catalogCode, locale)
      break
    case 'prezzo_scontato':
      value = getCatalogValue(
        product.price?.discount,
        catalogCode,
        locale,
      )?.value
      break
    case 'family_code':
      value = product.family?.code
      break
    case 'peso_lordo':
      value = product.grossWeight
      break
    case 'altezza':
      value = product.volume?.h
      break
    case 'disponibilita_illimitata':
      value = product.infiniteStocks
      break
    case 'dropship':
      value = product.isDropShip
      break
    case 'lunghezza':
      value = product.volume?.l
      break
    case 'prezzo_listino':
      value = getCatalogValue(
        product.price?.listing,
        catalogCode,
        locale,
      )?.value

      break
    case 'descrizione_logistica':
      value = product.logisticsDescription
      break
    case 'nome_produttore':
      value = manufacturerName
      break
    case 'nome':
      value = getCatalogValue(product.name, catalogCode, locale)
      break
    case 'peso_netto':
      value = product.netWeight
      break
    case 'note':
      value = product.notes
      break
    case 'pallet_qty':
      value = product.palletQty
      break
    case 'tipo_prodotto':
      value = product.productType
      break
    case 'descrizione_breve':
      value = getCatalogValue(product.shortDescription, catalogCode, locale)
      break
    case 'prezzo_acquisto':
      value = getCatalogValue(
        product.price?.purchase,
        catalogCode,
        locale,
      )?.value
      break
    case 'stato':
      value = product.status
      break
    case 'tax_rate':
      value = product.taxRate
      break
    case 'volume':
      value = product.volume?.total
      break
    case 'larghezza':
      value = product.volume?.w
      break
    default:
      break
  }
  return { [`KEY-${field.field}`]: value }
}

function parseProdsColumnField(
  field: ProductFileGeneratorField,
  //catalogCode: string,
  //locale: string,
): Partial<Column> {
  const header = field.field.includes('attribute') ? 'attribute' : field.field
  /*
  if (field.attribute) {
    header = `${header}:${field.attribute.code}`
  }
  const scope = []
  if (field.valuePerCatalog) {
    scope.push(catalogCode)
  }
  if (field.isLocalizable) {
    scope.push(locale)
  }
  if (scope.length) {
    header = `${header}[${scope.join('|')}]`
  }
  */
  return {
    key: `KEY-${field.field}`,
    header: header,
    width: header.length < 15 ? 12 : 26,
  }
}

function parseEmptyProdRow(keys: string[]): Partial<Row> {
  let rows: Partial<Row> = {}
  keys.map(
    (el) =>
      (rows = {
        ...rows,
        [`KEY-${el}`]: parseEmptyProdField(el),
      }),
  )
  return rows
}

function parseEmptyProdField(key: string) {
  let value = ''
  switch (key) {
    case 'azione':
      value = '[UPSERT, CREATE, UPDATE]'
      break
    case 'gestione_lotti_attiva':
      value = '[VERO, FALSO]'
      break
    case 'gestione_lotti_richiesto_lotto':
      value = '[VERO, FALSO]'
      break
    case 'gestione_lotti_richiesta_data_scadenza':
      value = '[VERO, FALSO]'
      break
    case 'gestione_lotti_richiesto_seriale':
      value = '[VERO, FALSO]'
      break
    case 'disponibilita_illimitata':
      value = '[VERO, FALSO]'
      break
    case 'dropship':
      value = '[VERO, FALSO]'
      break
  }
  return value
}

// Wrappers

function generateWrappersSheet(
  options: ProductFileGeneratorOptions,
  workbook: Workbook,
  products?: Product[],
) {
  const worksheet = workbook.addWorksheet('imballaggi')
  // Columns
  worksheet.columns = [
    ...startingColumns(options),
    ...generateColumns(productImportWrapperKeys),
  ]
  setHeaderStyle(worksheet, wrapperHeaderColor)
  // Rows
  if (products) {
    const rows: Partial<Row>[] = [
      parseEmptyWrapperRow(rowKeys(options, productImportWrapperKeys)),
    ]
    products.map((p) =>
      (p.wrappers ?? []).map((w) =>
        rows.push(
          parseWrapperRow(
            rowKeys(options, productImportWrapperKeys),
            p,
            w,
            options,
          ),
        ),
      ),
    )
    worksheet.addRows(rows)
  } else {
    const row: Partial<Row> = parseEmptyWrapperRow(
      rowKeys(options, productImportWrapperKeys),
    )
    worksheet.addRows([row])
  }
  worksheet.columns.map((c) => {
    c.numFmt = getFieldType(`${c.header ?? ''}`)
  })
}

function wrapperHeaderColor(key?: string) {
  switch (key) {
    case 'barcode':
    case 'moltiplicatore':
    case 'nome':
      return 'BD0000'
    default:
      return '000000'
  }
}

function parseWrapperRow(
  keys: string[],
  product: Product,
  wrapper: ProductWrapper,
  options: ProductFileGeneratorOptions,
): Partial<Row> {
  let rows: Partial<Row> = {}
  keys.map(
    (el) =>
      (rows = {
        ...rows,
        [`KEY-${el}`]: parseWrapperField(el, product, wrapper),
      }),
  )
  return {
    ...rows,
    [`KEY-${options.key}`]: firstKeyRow(options, product),
  }
}

function parseEmptyWrapperRow(keys: string[]): Partial<Row> {
  let rows: Partial<Row> = {}
  keys.map(
    (el) =>
      (rows = {
        ...rows,
        [`KEY-${el}`]: parseEmptyWrapperField(el),
      }),
  )
  return rows
}

function parseWrapperField(
  key: string,
  product: Product,
  wrapper: ProductWrapper,
) {
  let value: any
  switch (key) {
    case 'sku':
      value = `${product.SKU}`
      break
    case 'nome':
      value = wrapper.label
      break
    case 'barcode':
      value = wrapper.barcode
      break
    case 'autospedente':
      value = wrapper.isSelfShipping ?? ''
      break
    case 'moltiplicatore':
      value = wrapper.multiplier
      break
    case 'barcode_figlio':
      value = wrapper.childBarcode ?? ''
      break
    case 'lunghezza':
      value = wrapper.dimensions?.length ?? ''
      break
    case 'altezza':
      value = wrapper.dimensions?.height ?? ''
      break
    case 'larghezza':
      value = wrapper.dimensions?.width ?? ''
      break
    case 'volume':
      value = wrapper.dimensions?.volume ?? ''
      break
    case 'peso':
      value = wrapper.dimensions?.weight ?? ''
      break
  }

  return value
}

function parseEmptyWrapperField(key: string) {
  let value = ''
  switch (key) {
    case 'azione':
      value = '[PURGE, PULL, REPLACE, PUSH]'
      break
    case 'autospedente':
      value = '[VERO, FALSO]'
      break
  }
  return value
}

// Sites
function generateSitesSheet(
  options: ProductFileGeneratorOptions,
  workbook: Workbook,
  products?: Product[],
  sites?: Location[],
) {
  const worksheet = workbook.addWorksheet('ubicazioni')
  worksheet.columns = [
    ...startingColumns(options),
    ...generateColumns(productImportSiteKeys),
  ]
  setHeaderStyle(worksheet, siteHeaderColor)
  // Rows
  if (products) {
    const rows: Partial<Row>[] = [
      parseEmptySitesRow(rowKeys(options, productImportSiteKeys)),
    ]
    products.map((p) => {
      const productSites: Location[] = []
      products.map((product) => {
        product.locations?.map((prodLocation) => {
          productSites.push(
            ...(sites?.filter((l) => l._id === prodLocation._id) ?? []),
          )
        })
      })
      productSites.map((s) =>
        rows.push(
          parseSitesRow(rowKeys(options, productImportSiteKeys), options, p, s),
        ),
      )
    })
    worksheet.addRows(rows)
  } else {
    const row: Partial<Row> = parseEmptySitesRow(
      rowKeys(options, productImportSiteKeys),
    )
    worksheet.addRows([row])
  }
  worksheet.columns.map((c) => {
    c.numFmt = getFieldType(`${c.header ?? ''}`)
  })
}

function siteHeaderColor(key?: string) {
  switch (key) {
    case 'percorso':
      return 'BD0000'
    default:
      return '000000'
  }
}

function parseSitesRow(
  keys: string[],
  options: ProductFileGeneratorOptions,
  product: Product,
  site: Location,
): Partial<Row> {
  let rows: Partial<Row> = {}
  keys.map(
    (el) =>
      (rows = {
        ...rows,
        [`KEY-${el}`]: parseSiteField(el, product, site),
      }),
  )
  return {
    ...rows,
    [`KEY-${options.key}`]: firstKeyRow(options, product),
  }
}

function parseEmptySitesRow(keys: string[]): Partial<Row> {
  let rows: Partial<Row> = {}
  keys.map(
    (el) =>
      (rows = {
        ...rows,
        [`KEY-${el}`]: parseEmptySiteField(el),
      }),
  )
  return rows
}

function parseSiteField(key: string, product: Product, site: Location) {
  let value: any
  switch (key) {
    case 'sku':
      value = `${product.SKU}`
      break
    case 'percorso':
      value = site.path
      break
  }

  return value
}

function parseEmptySiteField(key: string) {
  let value = ''
  switch (key) {
    case 'azione':
      value = '[PURGE, PULL, REPLACE, PUSH]'
      break
  }
  return value
}

// Attributes

function generateAttributesSheet(
  options: ProductFileGeneratorOptions,
  workbook: Workbook,
  catalogCode: string,
  locale: string,
  products?: Product[],
) {
  const worksheet = workbook.addWorksheet('attributi')
  worksheet.columns = [
    ...startingColumns(options),
    ...generateColumns(productImportAttributeKeys),
  ]
  setHeaderStyle(worksheet, attributesHeaderColor)
  // Rows
  if (products) {
    const rows: Partial<Row>[] = [
      parseEmptyAttributesRow(rowKeys(options, productImportAttributeKeys)),
    ]
    products.map((p) =>
      (p.attributes ?? []).map((a) => {
        if (a.catalogCode === catalogCode) {
          rows.push(
            parseAttributesRow(
              rowKeys(options, productImportAttributeKeys),
              options,
              p,
              a,
              locale,
            ),
          )
        }
      }),
    )
    worksheet.addRows(rows)
  } else {
    const row: Partial<Row> = parseEmptyAttributesRow(
      rowKeys(options, productImportAttributeKeys),
    )
    worksheet.addRows([row])
  }
  worksheet.columns.map((c) => {
    c.numFmt = getFieldType(`${c.header ?? ''}`)
  })
}

function attributesHeaderColor(key?: string) {
  switch (key) {
    case 'valore':
      return 'BD0000'
    default:
      return '000000'
  }
}

function parseAttributesRow(
  keys: string[],
  options: ProductFileGeneratorOptions,
  product: Product,
  attribute: ProductCatalogAttribute,
  locale: string,
): Partial<Row> {
  let rows: Partial<Row> = {}
  keys.map(
    (el) =>
      (rows = {
        ...rows,
        [`KEY-${el}`]: parseAttributeField(el, product, attribute, locale),
      }),
  )
  return {
    ...rows,
    [`KEY-${options.key}`]: firstKeyRow(options, product),
  }
}

function parseEmptyAttributesRow(keys: string[]): Partial<Row> {
  let rows: Partial<Row> = {}
  keys.map(
    (el) =>
      (rows = {
        ...rows,
        [`KEY-${el}`]: parseEmptyAttributeField(el),
      }),
  )
  return rows
}

function parseAttributeField(
  key: string,
  product: Product,
  attribute: ProductCatalogAttribute,
  locale: string,
) {
  let value: any
  switch (key) {
    case 'sku':
      value = `${product.SKU}`
      break
    case 'codice_attributo':
      value = attribute.attributeCode
      break
    case 'valore':
      value = attribute.locales[locale]
      break
    case 'catalogo':
      value = attribute.catalogCode
      break
    case 'lingua':
      value = attribute.locales.default
      break
    case 'chiave_pim':
      value = ''
      break
  }

  return value
}

function parseEmptyAttributeField(key: string) {
  let value = ''
  switch (key) {
    case 'azione':
      value = '[PURGE, PULL, REPLACE, PUSH]'
      break
    case 'chiave_pim':
      value =
        '[NOME, DESCRIZIONE, DESCRIZIONE_BREVE, PREZZO_LISTINO, PREZZO_ACQUISTO, PREZZO_SCONTATO]'
      break
  }
  return value
}

// Barcodes

function generateBarcodesSheet(
  options: ProductFileGeneratorOptions,
  workbook: Workbook,
  products?: Product[],
) {
  const worksheet = workbook.addWorksheet('barcode')
  worksheet.columns = [
    ...startingColumns(options),
    ...generateColumns(productImportBarcodeKeys),
  ]
  setHeaderStyle(worksheet, barcodesHeaderColor)
  // Rows
  if (products) {
    const rows: Partial<Row>[] = [
      parseEmptyBarcodesRow(rowKeys(options, productImportBarcodeKeys)),
    ]
    products.map((p) =>
      (p.barcodes ?? []).map((b) => {
        rows.push(
          parseBarcodesRow(
            rowKeys(options, productImportBarcodeKeys),
            options,
            p,
            b,
          ),
        )
      }),
    )
    worksheet.addRows(rows)
  } else {
    const row: Partial<Row> = parseEmptyBarcodesRow(
      rowKeys(options, productImportBarcodeKeys),
    )
    worksheet.addRows([row])
  }
  worksheet.columns.map((c) => {
    c.numFmt = getFieldType(`${c.header ?? ''}`)
  })
}

function barcodesHeaderColor(key?: string) {
  switch (key) {
    case 'tipo':
    case 'valore':
      return 'BD0000'
    default:
      return '000000'
  }
}

function parseBarcodesRow(
  keys: string[],
  options: ProductFileGeneratorOptions,
  product: Product,
  barcode: ProductBarcode,
): Partial<Row> {
  let rows: Partial<Row> = {}
  keys.map(
    (el) =>
      (rows = {
        ...rows,
        [`KEY-${el}`]: parseBarcodeField(el, product, barcode),
      }),
  )
  return {
    ...rows,
    [`KEY-${options.key}`]: firstKeyRow(options, product),
  }
}

function parseEmptyBarcodesRow(keys: string[]): Partial<Row> {
  let rows: Partial<Row> = {}
  keys.map(
    (el) =>
      (rows = {
        ...rows,
        [`KEY-${el}`]: parseEmptyBarcodeField(el),
      }),
  )
  return rows
}

function parseBarcodeField(
  key: string,
  product: Product,
  barcode: ProductBarcode,
) {
  let value: any
  switch (key) {
    case 'sku':
      value = `${product.SKU}`
      break
    case 'tipo':
      value = barcode.type
      break
    case 'valore':
      value = barcode.value
      break
    case 'qty':
      value = barcode.qty
      break
  }
  return value
}

function parseEmptyBarcodeField(key: string) {
  let value = ''
  switch (key) {
    case 'azione':
      value = '[PURGE, PULL, REPLACE, PUSH]'
      break
    case 'tipo':
      value = '[EAN13 | EAN8 | MINSAN | GENERIC | GTIN14]'
      break
  }
  return value
}

// Tags

function generateTagsSheet(
  options: ProductFileGeneratorOptions,
  workbook: Workbook,
  products?: Product[],
) {
  const worksheet = workbook.addWorksheet('tag')
  worksheet.columns = [
    ...startingColumns(options),
    ...generateColumns(productImportTagKeys),
  ]
  setHeaderStyle(worksheet, tagsHeaderColor)
  // Rows
  if (products) {
    const rows: Partial<Row>[] = [
      parseEmptyTagsRow(rowKeys(options, productImportTagKeys)),
    ]
    products.map((p) =>
      (p.tags ?? []).map((t) => {
        rows.push(
          parseTagsRow(rowKeys(options, productImportTagKeys), options, p, t),
        )
      }),
    )
    worksheet.addRows(rows)
  } else {
    const row: Partial<Row> = parseEmptyTagsRow(
      rowKeys(options, productImportTagKeys),
    )
    worksheet.addRows([row])
  }
  worksheet.columns.map((c) => {
    c.numFmt = getFieldType(`${c.header ?? ''}`)
  })
}

function tagsHeaderColor(key?: string) {
  switch (key) {
    case 'valore':
      return 'BD0000'
    default:
      return '000000'
  }
}

function parseTagsRow(
  keys: string[],
  options: ProductFileGeneratorOptions,
  product: Product,
  tag: string,
): Partial<Row> {
  let rows: Partial<Row> = {}
  keys.map(
    (el) =>
      (rows = {
        ...rows,
        [`KEY-${el}`]: parseTagField(el, product, tag),
      }),
  )
  return {
    ...rows,
    [`KEY-${options.key}`]: firstKeyRow(options, product),
  }
}

function parseEmptyTagsRow(keys: string[]): Partial<Row> {
  let rows: Partial<Row> = {}
  keys.map(
    (el) =>
      (rows = {
        ...rows,
        [`KEY-${el}`]: parseEmptyTagField(el),
      }),
  )
  return rows
}

function parseTagField(key: string, product: Product, tag: string) {
  let value: any
  switch (key) {
    case 'sku':
      value = `${product.SKU}`
      break
    case 'valore':
      value = tag
      break
  }
  return value
}

function parseEmptyTagField(key: string) {
  let value = ''
  switch (key) {
    case 'azione':
      value = '[PURGE, PULL, REPLACE, PUSH]'
      break
  }
  return value
}

/// FROM HERE ON, EVERYTHING IS RELATED TO THE OLD IMPORT

export function OLDgenerateUpdateXlsFile(
  options: ProductFileGeneratorOptions,
  catalogCode = CATALOG_DEFAULT_CODE,
  locale = LOCALE_DEFAULT_CODE,
  products?: Product[],
  brands?: Brand[],
): void {
  console.log('OLDgenerateUpdateXlsFile catalogCode', catalogCode)

  // Create workbook
  const workbook = new Workbook()
  if (options.tabs.includes('PRODUCTS')) {
    const worksheet = workbook.addWorksheet('prodotti')

    const productFields: ProductFileGeneratorField[] = OLDparseOptionsFields(
      options.fields,
    )

    // Columns
    const columns: Partial<Column>[] = [
      {
        header: String(options.key),
        key: `KEY-${options.key}`,
        width: OLDcolumnWidth(options.key),
      },
      ...productFields.map((field) =>
        OLDparseColumnField(field, catalogCode, locale),
      ),
    ]
    worksheet.columns = columns
    worksheet.getRow(1).protection = {
      locked: true,
    }
    worksheet.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'BCBCBC' },
    }
    worksheet.getRow(1).font = {
      bold: true,
    }

    // Rows
    if (products) {
      const _brands: Record<string, string> =
        brands?.reduce((acc, b) => ({ ...acc, [b._id]: b.name }), {}) || {}
      const rows = products.map((p) =>
        OLDparseProductRow(
          p,
          p.brandId ? _brands[p.brandId] : undefined,
          options,
          catalogCode,
        ),
      )
      worksheet.addRows(rows)
    }
  }

  workbook.xlsx
    .writeBuffer()
    .then((buffer: BlobPart) =>
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `prodotti.xlsx`,
      ),
    )
}

function OLDparseOptionsFields(
  fields: ProductFileGeneratorField[],
): ProductFileGeneratorField[] {
  const result: ProductFileGeneratorField[] = []
  fields.map((f) => {
    result.push({ ...f, field: OLDparseFieldKey(f.field) })
  })
  return result
}

function OLDparseFieldKey(key: string) {
  switch (key) {
    case 'nome':
      return 'name'
    case 'stato':
      return 'status'
    case 'nome_brand':
      return 'brand'
    case 'descrizione':
      return 'description'
    case 'tipo_prodotto':
      return 'productType'
    case 'prezzo_acquisto':
      return 'price.purchase'
    case 'prezzo_listino':
      return 'price.listing'
    case 'prezzo_scontato':
      return 'price.discount'
    case 'tax_rate':
      return 'taxRate'
    case 'pallet_qty':
      return 'palletQty'
    case 'larghezza':
      return 'width'
    case 'lunghezza':
      return 'length'
    case 'altezza':
      return 'height'
    case 'peso_netto':
      return 'netWeight'
    case 'peso_lordo':
      return 'grossWeight'
    case 'note':
      return 'notes'
    case 'descrizione_logistica':
      return 'logisticsDescription'
    case 'disponibilita_illimitata':
      return 'infiniteStock'
    case 'dogana_descrizione':
      return 'customsDescription'
    case 'dogana_hs_code':
      return 'customsHsCode'
    case 'dogana_nazione_origine':
      return 'customsOriginCountry'
    default:
      return key
  }
}

function OLDcolumnWidth(key?: string): number | undefined {
  switch (key) {
    case 'azione':
      return 26
    case 'sku':
      return 12
    default:
      return undefined
  }
}

function OLDparseProductRow(
  product: Product,
  brandName: string | undefined,
  options: ProductFileGeneratorOptions,
  catalogCode = CATALOG_DEFAULT_CODE,
  locale = LOCALE_DEFAULT_CODE,
): Partial<Row> {
  const barcode = product.barcodes ? product.barcodes[0].value : product.SKU
  const key =
    options.key === 'id'
      ? product._id
      : options.key === 'sku'
        ? product.SKU
        : barcode
  return {
    [`KEY-${options.key}`]: key,
    ...options.fields.reduce(
      (acc, field) => ({
        ...acc,
        ...OLDparseProductField(product, brandName, field, catalogCode, locale),
      }),
      {},
    ),
  }
}

function OLDparseProductField(
  product: Product,
  brandName: string | undefined,
  field: ProductFileGeneratorField,
  catalogCode: string,
  locale: string,
) {
  const key = field.field.includes('attribute') ? 'attribute' : field.field
  let value = undefined

  if (field.method !== FileUpdateMethod.unset) {
    switch (key) {
      case 'sku':
        value = product.SKU
        break
      case 'attribute':
        value = getProductAttributeByField(product, field, catalogCode, locale)
        break
      case 'barcode':
        value = product.barcodes ? product.barcodes[0].value : undefined
        break
      case 'brand':
      case 'nome_brand':
        value = brandName
        break
      case 'description':
      case 'descrizione':
        value = getCatalogValue(product.description, catalogCode, locale)
        break
      case 'name':
      case 'nome':
        value = getCatalogValue(product.name, catalogCode, locale)
        break
      case 'notes':
      case 'note':
        value = product.notes
        break
      case 'price.discount':
      case 'prezzo_scontato':
        value =
          product.price?.discount !== undefined
            ? getCatalogPriceValue(product.price.discount, catalogCode, locale)
            : ''
        break
      case 'price.listing':
      case 'prezzo_listino':
        value =
          product.price?.listing !== undefined
            ? getCatalogPriceValue(product.price.listing, catalogCode, locale)
            : ''
        break
      case 'price.purchase':
      case 'prezzo_acquisto':
        value =
          product.price?.purchase !== undefined
            ? getCatalogPriceValue(product.price.purchase, catalogCode, locale)
            : ''
        break
      case 'status':
      case 'stato':
        value = product.status
        break
      case 'tag':
        value = (product.tags || []).join(',')
        break
      default:
        break
    }
  } else {
    value = 'TRUE'
  }
  return { [OLDparseFieldKey(field.field)]: value }
}

function OLDparseColumnField(
  field: ProductFileGeneratorField,
  catalogCode: string,
  locale: string,
): Partial<Column> {
  const key = field.field.includes('attribute') ? 'attribute' : field.field
  let header = `${field.method}@${key}`

  if (field.attribute) {
    header = `${header}:${field.attribute.code}`
  }

  const scope = []
  if (field.valuePerCatalog) {
    scope.push(catalogCode)
  }

  if (field.isLocalizable) {
    scope.push(locale)
  }

  if (scope.length) {
    header = `${header}[${scope.join('|')}]`
  }

  return {
    key: field.field,
    header,
  }
}
