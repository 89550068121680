import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, concat, last, switchMap } from 'rxjs'
import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import {
  Order,
  OrderAdditionalPackage,
  OrderBulkUpdate,
  OrderBulkUpdateResponse,
  OrderChangeStatusAction,
  OrderCounters,
  OrderCoverage,
  OrderDashboard,
  OrderExportAmazonShipData,
  OrderExportData,
  OrderPackage,
  OrderSearchParams,
  Shipment,
} from './order.model'
import { Page } from '../../models/util.model'
import { Printers } from '../print-node/print-node.model'
import { Product } from '../products'

const MODEL = 'orders'
const VERSION = 'v3'
const OLD_VERSION = 'v2'

@Injectable({
  providedIn: 'root',
})
export class OrdersService extends CrudService {
  private deprecatedUrl: string

  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
    )
    this.deprecatedUrl = `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${OLD_VERSION}/${MODEL}`
  }

  /**
   * Create a new order
   * @param order - The order to create
   * @returns The observable<Order> to create the order
   */
  create$(order: Order): Observable<Order> {
    return this._create$<Order>(order)
  }

  /**
   * Read a order by ID
   * @param orderId - The order ID
   * @returns The observable<Order> for read the order
   */
  read$(orderId: string): Observable<Order> {
    return this._read$<Order>(orderId)
  }

  /**
   * Update a order by ID
   * @param orderId - The order ID
   * @param order - The order body to update
   * @returns The observable<Order> for update the order
   */
  update$(orderId: string, order: Order): Observable<Order> {
    return this._update$<Order>(orderId, order)
  }

  /**
   * Create or update a order by ID
   * @param orderId - The order ID
   * @param order - The order body to update
   * @returns The observable<Order> for update the order
   */
  upsert$(order: Order): Observable<Order> {
    return this._upsert$<Order>(order, order._id)
  }

  /**
   * Delete a order by ID
   * @param orderId - The order ID
   * @returns The observable<Order> for delete the order
   */
  delete$(orderId: string): Observable<Order> {
    return this._delete$<Order>(orderId)
  }

  /**
   * Search orders by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Order>> for search orders
   */
  search$(
    params?: OrderSearchParams,
    returnAll = false,
  ): Observable<Page<Order>> {
    return this._search$<Order>(params, returnAll)
  }

  /**
   * List orders by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Order[]> for list orders
   */
  list$(params?: OrderSearchParams, returnAll = false): Observable<Order[]> {
    return this._list$<Order>(params, returnAll)
  }

  /**
   * Cancel an order by ID
   * @param orderId - The order ID
   * @returns The observable<Order> for cancel the order
   */
  cancel$(orderId: string): Observable<Order> {
    return this.http.post<Order>(`${this.apiUrl}/${orderId}/cancel`, {})
  }

  /**
   * Cancel an order shipment by ID
   * @param orderId - The order ID
   * @returns The observable<Order> for cancel the shipment
   */
  cancelShipment$(orderId: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${orderId}/shipment/cancel`, {})
  }

  /**
   * Confirm an order by ID
   * @param orderId - The order ID
   * @returns The observable<Order> for confirm the order
   */
  confirm$(orderId: string): Observable<Order> {
    return this.http.post<Order>(`${this.apiUrl}/${orderId}/confirm`, {})
  }

  /**
   * Deliver an order by ID
   * @param orderId - The order ID
   * @returns The observable<Order> for deliver the order
   */
  deliver$(orderId: string): Observable<Order> {
    return this.http.post<Order>(`${this.apiUrl}/${orderId}/deliver`, {})
  }

  /**
   * DropShip an order by ID
   * @param orderId - The order ID
   * @returns The observable<Order> for dropship the order
   */
  dropship$(orderId: string): Observable<Order> {
    return this.http.post<Order>(`${this.apiUrl}/${orderId}/dropship`, {})
  }

  /**
   * Download order label by ID and labelID
   * @param orderId - The order ID
   * @param labelId - The label ID
   * @returns The observable<any> for download the order label
   */
  downloadLabel$(orderId: string, labelId: string): Observable<any> {
    return this.http.get(
      `${this.apiUrl}/${orderId}/files/shipment/labels/${labelId}`,
      {
        responseType: 'blob',
      },
    )
  }

  /**
   * Print order labels
   * @param orderId - The order ID
   * @param printerId - The printer ID
   * @returns The observable<void> for print the order labels
   */
  printLabel$(orderId: string, printers: Printers): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${orderId}/print`, {
      printers,
    })
  }

  /**
   * Download the order summary
   * @param orderId - The order ID
   * @returns The observable<any> for download the order summary
   */
  downloadSummary$(orderId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/${orderId}/files/summary`, {
      responseType: 'blob',
    })
  }

  /**
   * Print order summary
   * @param orderId - The order ID
   * @param printerId - The printer ID
   * @returns The observable<void> for print the order summary
   */
  printSummary$(orderId: string, printerId: string): Observable<void> {
    return this.http.post<void>(
      `${this.apiUrl}/${orderId}/files/summary/print`,
      { printerId },
    )
  }

  /**
   * Hand an order by ID
   * @param orderId - The order ID
   * @returns The observable<Order> for hand the order
   */
  hand$(orderId: string): Observable<Order> {
    return this.http.post<Order>(`${this.apiUrl}/${orderId}/hand`, {})
  }

  /**
   * Manage an order by ID (status PROCESSING)
   * @param orderId - The order ID
   * @returns The observable<Order> for manage the order
   */
  manage$(orderId: string): Observable<Order> {
    return this.http.post<Order>(`${this.apiUrl}/${orderId}/manage`, {})
  }

  /**
   * Pack an order by ID
   * @param orderId - The order ID
   * @param printers - The printers IDs where print the label
   * @returns The observable<Order> for pack the order
   */
  pack$(
    orderId: string,
    params: {
      printers?: Printers
      packages?: OrderPackage[]
      additionalPackages?: OrderAdditionalPackage[]
    } = {},
  ): Observable<Order> {
    return this.http.post<Order>(`${this.apiUrl}/${orderId}/pack`, params)
  }

  /**
   * Park an order by ID
   * @param orderId - The order ID
   * @returns The observable<Order> for park the order
   */
  park$(orderId: string): Observable<Order> {
    return this.http.post<Order>(`${this.apiUrl}/${orderId}/park`, {})
  }

  /**
   * Park an order package by ID
   * @param orderId - The order ID
   * @returns The observable<Order> for park the package
   */
  parkPackage$(orderId: string, packageId: string): Observable<Order> {
    return this.http.post<Order>(
      `${this.apiUrl}/${orderId}/packages/${packageId}/park`,
      {},
    )
  }

  /**
   * Pend an order by ID
   * @param orderId - The order ID
   * @returns The observable<Order> for pend the order
   */
  pend$(orderId: string): Observable<Order> {
    return this.http.post<Order>(`${this.apiUrl}/${orderId}/pend`, {})
  }

  /**
   * Process an order by ID
   * @param orderId - The order ID
   * @returns The observable<Order> for process the order
   */
  process$(orderId: string): Observable<Order> {
    return this.http.post<Order>(`${this.apiUrl}/${orderId}/process`, {})
  }

  /**
   * List order products
   * @param orderId - The order ID
   * @returns The observable<Order> for process the order
   */
  getProducts$(
    orderId: string,
    params?: { limit?: number; offset?: number },
  ): Observable<Product[]> {
    return this.http.get<Product[]>(`${this.apiUrl}/${orderId}/products`, {
      params,
    })
  }

  /**
   * Ship an order by ID
   * @param orderId - The order ID
   * @returns The observable<Order> for ship the order
   */
  ship$(orderId: string): Observable<Order> {
    return this.http.post<Order>(`${this.apiUrl}/${orderId}/ship`, {})
  }

  /**
   * Get order shipment
   * @param orderId - The order ID
   * @returns The observable<Order> for ship the order
   */
  getShipment$(orderId: string): Observable<Shipment> {
    return this.http.get<Shipment>(`${this.apiUrl}/${orderId}/shipment`)
  }

  /**
   * Duplicate order by ID
   * @param orderId - The order ID
   * @returns The observable<Order> for duplicate the order
   */
  duplicate$(orderId: string): Observable<Order> {
    return this.http.post<Order>(`${this.apiUrl}/${orderId}/duplicate`, {})
  }

  /**
   * Split order
   * @param orderId - The order ID
   * @param currentOrder - The new shape of the order
   * @param newOrders - New orders to create
   * @returns The observable<Order[]> for split the order
   */
  split$(orderId: string, children: Order[]): Observable<Order[]> {
    return this.http.post<Order[]>(`${this.apiUrl}/${orderId}/split`, {
      children,
    })
  }

  /**
   * Pick a product from the order
   * @param orderId - The order ID
   * @param rowId - The order row ID
   * @param params - The pick params
   * @returns The observable<Order> for pick the order product
   */
  pick$(
    orderId: string,
    rowId: string,
    params: {
      productId: string
      quantity: number
      locationId: string
      lot?: string
      expirationDate?: string
      serial?: string
    },
  ): Observable<Order> {
    return this.http.post<Order>(
      `${this.apiUrl}/${orderId}/rows/${rowId}/pick`,
      params,
    )
  }

  /**
   * Reset the order picking: change the status from PROCESSING to CONFIRMED
   * @param orderId - The order ID
   * @returns The observable<Order> for reset the picking
   */
  resetPicking$(orderId: string): Observable<Order> {
    return this.http.post<Order>(`${this.apiUrl}/${orderId}/picking/reset`, {})
  }

  /**
   * Get dashboard order results
   * @param startDate - The start date
   * @param endDate - The end date
   * @returns The observable<OrderDashboard> for dashboard search
   */
  dashboard$(startDate: string, endDate: string): Observable<OrderDashboard> {
    return this.http.get<OrderDashboard>(`${this.apiUrl}/stats/dashboard`, {
      params: {
        'header.rifDate:ge': startDate,
        'header.rifDate:lt': endDate,
      },
    })
  }

  /**
   * Get order counters
   * @returns The observable<OrderCounters> for order count
   */
  counts$(): Observable<OrderCounters> {
    return this.http.get<OrderCounters>(`${this.apiUrl}/stats/status`)
  }

  /**
   * Get old orders upload url
   * @deprecated
   * @returns the url to upload orders by file
   */
  getOldUploadOrdersUrl(): string {
    return `${this.deprecatedUrl}/import-excel/`
  }

  /**
   * Get orders upload url
   * @returns the url to upload orders by file
   */
  getUploadOrdersUrl(): string {
    return `${this.apiUrl}/import-excel/`
  }

  /**
   * Get orders upload url
   * @returns the url to upload Amazon orders by file
   */
  getUploadAmazonOrdersUrl(): string {
    return `${this.apiUrl}/import-amazon-txt`
  }

  /**
   * Get order rows upload url
   * @deprecated
   * @param orderId - The order ID
   * @returns the url to upload the order rows by file
   */
  getUploadOrderRowsUrl(orderId: string): string {
    return `${this.deprecatedUrl}/${orderId}/import-rows/`
  }

  /**
   * Download template for upload orders
   * @deprecated
   * @returns the observable<Blob> for download the template
   */
  downloadOldOrdersUploadTemplate$(): Observable<any> {
    return this.http.get(`${this.deprecatedUrl}/template`, {
      responseType: 'blob',
    })
  }

  /**
   * Download template for upload orders
   * @returns the observable<Blob> for download the template
   */
  downloadOrdersUploadTemplate$(): Observable<any> {
    return this.http.get(`${this.apiUrl}/template`, {
      responseType: 'blob',
    })
  }

  /**
   * Download template for upload order rows
   * @deprecated
   * @returns the observable<Blob> for download the template
   */
  downloadOrderRowsUploadTemplate$(): Observable<any> {
    return this.http.get(`${this.deprecatedUrl}/single-template`, {
      responseType: 'blob',
    })
  }

  /**
   * Export order promotions by email
   * @deprecated
   * @param exportData - The export params
   * @returns the Observable<void> for export order promotions
   */
  export$(exportData: OrderExportData): Observable<void> {
    return this.http.post<void>(`${this.deprecatedUrl}/export`, exportData)
  }

  /**
   * Get order coverage by ID
   * @param orderId - The order ID
   * @returns The Observable<OrderCoverage> for get order coverage
   */
  coverage$(orderId: string): Observable<OrderCoverage> {
    return this.http.get<OrderCoverage>(`${this.apiUrl}/${orderId}/coverage`)
  }

  /**
   * Get orders coverage by warehouse
   * @param warehouseId - The warehouse ID
   * @param orderIds - The order IDs
   * @returns The Observable<OrderCoverage[]> for get orders coverage
   */
  multiCoverage$(
    warehouseId: string,
    orderIds: string[],
  ): Observable<OrderCoverage[]> {
    return this.http.post<OrderCoverage[]>(`${this.apiUrl}/coverage`, {
      warehouseId,
      orderIds,
    })
  }

  /**
   * Bulk update orders
   * @param params - The bulk update params
   * @returns The observable<OrderBulkUpdateResponse> for update orders
   */
  bulkUpdate$(params: OrderBulkUpdate): Observable<OrderBulkUpdateResponse> {
    return this.http.post<OrderBulkUpdateResponse>(
      `${this.apiUrl}/bulk-update`,
      params,
    )
  }

  /**
   * Change order status
   * @param orderId - The order ID
   * @param action - The change status action
   * @param actionOptions - The action options
   * @returns The Observable<Order> for change the order status
   */
  changeStatus$(
    orderId: string,
    action: OrderChangeStatusAction,
    params?: {
      printers?: Printers
      packages?: OrderPackage[]
      additionalPackages?: OrderAdditionalPackage[]
    },
  ): Observable<Order> {
    switch (action) {
      case 'cancel':
        return this.cancel$(orderId)
      case 'cancelShipment':
        return this.cancelShipment$(orderId).pipe(
          switchMap(() => this.read$(orderId)),
        )
      case 'confirm':
        return this.confirm$(orderId)
      case 'deliver':
        return this.deliver$(orderId)
      case 'dropship':
        return this.dropship$(orderId)
      case 'hand':
        return this.hand$(orderId)
      case 'manage':
        return this.manage$(orderId)
      case 'pack':
        return this.pack$(orderId, params)
      case 'park':
        return this.park$(orderId)
      case 'pend':
        return this.pend$(orderId)
      case 'process':
        return this.process$(orderId)
      case 'ship':
        return this.ship$(orderId)
    }
  }

  /**
   * Generates the path to which attachments for an order must be uploaded
   * @param orderId - The order ID
   * @returns the path
   */
  getOrderAttachmentsUploadUrl(orderId: string): string {
    return `${this.apiUrl}/${orderId}/attachments`
  }

  /**
   * Creates an attachment for an order
   * @param orderId - The order ID
   * @param attachment - The attachment as binary file
   * @returns the updated observable<Order>
   */
  createAttachment$(orderId: string, attachment: any): Observable<Order> {
    return this.http.post<Order>(`${this.apiUrl}/${orderId}/attachments`, {
      body: attachment,
    })
  }

  /**
   * Creates multiple attachments for an order
   * @param orderId - The order ID
   * @param attachments - The list of attachments as binary files
   * @returns the updated observable<Order>
   */
  createAttachments$(orderId: string, attachments: [any]): Observable<Order> {
    return concat(
      ...attachments.map((attachment) =>
        this.createAttachment$(orderId, attachment),
      ),
    ).pipe(last())
  }

  /**
   * Deletes an attachment from an order
   * @param orderId - The order ID
   * @param attachmentId - The attachment ID
   */
  deleteAttachment$(orderId: string, attachmentId: string): Observable<Order> {
    return this.http.delete<Order>(
      `${this.apiUrl}/${orderId}/attachments/${attachmentId}`,
      { body: {} },
    )
  }

  /**
   * Downloads an attachment from an order
   * @param productId - The product ID
   * @param attachmentId - The attachment ID
   * @returns the observable<Attachment>
   */
  downloadAttachment$(orderId: string, attachmentId: string): Observable<any> {
    return this.http.get(
      `${this.apiUrl}/${orderId}/attachments/${attachmentId}/content`,
      { responseType: 'blob' },
    )
  }

  /**
   * Export Amazon shippings
   * @param exportData - The export params
   * @returns the observable<void> for export
   */
  exportAmazonShip$(exportData: OrderExportAmazonShipData): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/import-amazon-txt`, exportData)
  }
}
